<template>
  <div
    :style="getElementStyle"
    v-if="!loading && data.properties.filed_content != 'Hide'"
  >
    <el-row class="is-mobile">
      <el-col
        v-if="
          !data.properties.hideLabel || data.properties.filed_content != 'Hide'
        "
      >
        <el-checkbox
          v-model="form[data.key]"
          :change="changeField"
          :disabled="isFromDocument ? false : isReadOnly() || checkReadonly() || readonly()"
          @change="applyFormRules"
          :style="[ {'white-space': 'normal' , display: 'flex'}, getLabelStyles]"
          class="el-checkbox custom-checkbox"
          :class="{ 'highlighted-label': highlight }"
        >
        <span
            v-if="data.validations.required && hasLabel && !isView && data.properties.hideLabel "
            style="color: red; font-weight: bold"
          >
            *
          </span>
        <div v-if="!data.properties.hideLabel">
          <template v-if="data.forConfirmation">
            <span v-html="data.content"></span> <span
            v-if="data.validations.required && hasLabel && !isView "
            style="color: red; font-weight: bold"
          >
            *
          </span>  
           
          </template>
          <template v-else>
            <label
            for="text"
            v-if="hasLabel && !data.properties.hideLabel"
            :style="getLabelStyles"
          >{{ label||data.label}}            
          </label>
          </template>
          <span
          v-if="data.validations.required && hasLabel && !isView && !data.forConfirmation "
          style="color: red; font-weight: bold"
        >
          *
        </span>
            
        </div>
         
        </el-checkbox>
        <span style="margin-left: 5px;">
          <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
        </span>
      </el-col>
      <!-- <el-col :span="data.description ? 4 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
      </el-col> -->
    </el-row>
    <!-- <p v-if="isView" :style="getStyle">
          <template v-if="form && form[data.key] && form[data.key].length"> {{ form[data.key].join(',') }} </template>
          <template v-else>-</template>
        </p> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
export default {
  name: "templates-formComponentsExecute-CheckBoxExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "fieldsData",
    "isFromDocument",
    "singleCompanyTemplateCheckBoxArray",
    "checkIsDisabled",
    "colorFields",
    "highlight",
    "fromEntityViews"
  ],
  computed: {
    ...mapGetters("auth", ["getAutheniticatedUser"]),
    getStyle() {
      return this.hasLabel
        ? `height:${this.data.height - 5}px;width:${this.data.width - 5}px;`
        : "";
    },
  },
  mounted() {
    this.loading = true;
    //this.defaultMethod();
    if (!this.form[this.data.key]) {
      this.$set(this.form, this.data.key, false);
    }
    if (this.data && this.data.group) {
      this.group = this.data.group;
    }
    if(this.form[this.data.key] !== ''){
      this.applyFormRules()
    }
    this.isReadOnly();
    this.loading = false;
  },
  data() {
    return {
      validations: [],
      errorMsg: "",
      maxMinErrMsg: "",
      hasError: false,
      maxMinErr: false,
      groups: [],
      showAddData: false,
      loading: false,
      group: {
        label: "",
        key: "",
        max_value: 0,
        min_value: 0,
      },
      showLabel: true,
      isDefalutPos: true,
    };
  },
  methods: {
    defaultMethod(){
      if (this.data.default_value && this.form &&this.data.key && !this.form[this.data.key]) {
      //  this.form[this.data.key] = this.data.default_value;
      this.$set(this.form, this.data.key, this.data.default_value);
    }
   },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    readonly() {
      if (
        this.data.field_asignable === "read_only" &&
        this.data.is_entity_variables
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    isReadOnly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        if (this.data.styles.labelStyle == "right") {
          this.isDefalutPos = false;
        }
      }
      // method from mixin
      let isReadonly =
        !this.fieldFilledByCurrentUser(this.data.filled_by) || this.isView;
      if (!isReadonly && this.data.group_key) {
        let currentActiveGroupOfAField = (
          this.singleCompanyTemplateCheckBoxArray || []
        ).find((gkey) => gkey.key == this.data.group_key);
        let groupSelectedFields = [];
        if (this.fieldsData && this.fieldsData.length) {
          groupSelectedFields = this.fieldsData.filter(
            (field) =>
              field.group_key &&
              field.group_key == this.data.group_key &&
              this.form[field.key]
          );
        }
        let minValue = parseInt(
          currentActiveGroupOfAField && currentActiveGroupOfAField.min_value
        );
        let maxValue = parseInt(
          currentActiveGroupOfAField && currentActiveGroupOfAField.max_value
        );
        let fieldCount = groupSelectedFields?.length;
        fieldCount++;
        let validCheck = this.checkInBetween(fieldCount, maxValue, minValue);
        if ((minValue === 0 && maxValue === 0) || !maxValue) {
          return false;
        } else if (
          validCheck ||
          groupSelectedFields.length <= 0 ||
          this.form[this.data.key]
        ) {
          return false;
        } else {
          return true;
        }
      }
      return isReadonly;
    },
    changeField() {
      this.$set(this.form, this.data.key, !this.form[this.data.key]);
    },
    checkInBetween(fieldCount, maxValue, minValue) {
      Number.prototype.between = function (a, b) {
        var min = Math.min(a, b),
          max = Math.max(a, b);

        return this >= min && this <= max;
      };
      return fieldCount.between(minValue, maxValue);
    },
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss">
/* Use more specific selector or !important */
.custom-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: #333333 !important;
}
 .highlighted-label {
  color: red;
}

.is-mobile{
  @media (max-width: 991.98px) {
    margin-top: 70px;
  }
}
</style>